<template>
  <div>
    <form v-if="data == null && loaded" @submit="addEntry">
      <v-card>
        <v-card-title>{{ $t("today.entry") }}</v-card-title>
        <v-card-text>
          <v-text-field :label="$t('today.title')" :disabled="add_loading" v-model="add_title"></v-text-field>
          <v-textarea :label="$t('today.text')" :disabled="add_loading" v-model="add_text"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn type="submit" :loading="add_loading" color="primary" text>{{ $t("today.save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </form>
    <div v-else-if="data != null && loaded">
      <v-form v-if="edit_mode" @submit="editEntry">
        <v-card>
          <v-card-title>
            <v-text-field :label="$t('today.title')" :disabled="edit_loading" v-model="edit_title"></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-textarea :label="$t('today.text')" :disabled="edit_loading" v-model="edit_text"></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn type="submit" :loading="edit_loading" text>{{ $t("today.save") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-card v-else>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text v-html="text.replace(/(?:\r\n|\r|\n)/g, '<br>')"></v-card-text>
        <v-card-actions>
          <v-btn @click="edit_mode = true" text>{{ $t("today.edit") }}</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <v-card v-else>
      <v-card-title>
        <v-skeleton-loader class="mx-auto" width="100%" type="heading"></v-skeleton-loader>
      </v-card-title>
      <v-card-text>
        <v-skeleton-loader
          class="mx-auto"
          width="100%"
          style="margin:0;"
          v-for="n in 3"
          :key="n"
          type="text"
        ></v-skeleton-loader>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { Notifications } from "../notification";
import Entries from "../logic/Entries";

export default {
  props: {
    date: String
  },
  data() {
    return {
      data: null,

      title: "",
      text: "",

      add_title: "",
      add_text: "",
      add_loading: false,

      edit_mode: false,
      edit_title: "",
      edit_text: "",
      edit_loading: false,

      loaded: false
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      var response = await Entries.loadSpecifcDate(this.date);

      if (response.isOK) {
        if (response.data && response.data.length >= 1) {
          this.data = response.data[0];

          this.title = this.data.data.title;
          this.text = this.data.data.text;

          this.edit_title = this.data.data.title;
          this.edit_text = this.data.data.text;
        } else {
          this.data = null;
          this.title = "",
          this.text = "";
          this.edit_title = "";
          this.edit_text = "";
        }
      } else {
        Notifications.show(response.message);
      }

      this.loaded = true;
    },
    async addEntry(e) {
      e.preventDefault();

      this.add_loading = true;
      var response = await Entries.add(this.add_title, this.add_text, this.date);
      this.add_loading = false;

      if (response.isOK) {
        Notifications.show(this.$t("today.notification.entry_saved"));
        this.data = {
          id: response.data.id,
          data: {
            title: this.add_title,
            text: this.add_text
          }
        };

        this.title = this.add_title;
        this.text = this.add_text;

        this.edit_title = this.add_title;
        this.edit_text = this.add_text;
      } else {
        Notifications.show(response.message);
      }
    },
    async editEntry(e) {
      e.preventDefault();

      this.edit_loading = true;

      var response = await Entries.edit(
        this.data.id,
        this.edit_title,
        this.edit_text
      );
      this.edit_loading = false;
      if (response.isOK) {
          Notifications.show(this.$t("today.notification.entry_saved"));
          this.title = this.edit_title;
          this.text = this.edit_text;
          this.edit_mode = false;
      } else {
        Notifications.show(response.message);
      }
    }
  },
  watch: {
    date() {
      this.add_title = "";
      this.add_text = "";
      this.edit_text = "";
      this.edit_title = "";

      this.data = null;

      this.loaded = false;
      this.load();
    }
  }
};
</script>

<style>
</style>