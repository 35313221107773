import { storage } from "../schmucklicloud";

const Mood = {
    /**
     * Updates the mood for the given day.
     * @param {Number} value Value of the mood (1 = satisified, 2 = neutral, 3 = sad)
     * @param {String} date The date string, where the mood should be saved. 
     */
    update: async function(value, date) {
        var response = await storage.get("mood", [{
            column: "date",
            operator: "==",
            value: date
        }]);

        var crud_response;
        if (response.isOK) {
            if (!response.data || response.data.length === 0) {
                crud_response = await storage.insert("mood", {
                    value: value,
                    date: date
                });
            } else {
                var id = response.data[0].id;
                crud_response = await storage.update("mood", id, {
                    value: value,
                    date: date
                });
            }
        } else {
             crud_response = false;
        }

        return crud_response;
    },

    loadToday: async function() {
        var today = new Date();
        var s_today =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1 + "").padStart(2, "0") +
            "-" +
            (today.getDate() + "").padStart(2, "0");
        
        return this.loadSpecifcDate(s_today);
    },
    loadSpecifcDate: async function(date) {
        var response = await storage.get("mood", [
            {
                column: "date",
                operator: "==",
                value: date
            }
        ]);

        return response;
    }
};

export default Mood;
