<template>
  <div style="width:100%;padding:10px;">
    <v-layout row wrap style="margin:0;">
      <v-flex xs6 sm6>
        <h1>{{ $t("today.header") }}</h1>
      </v-flex>
      <v-flex xs6 sm6 style="text-align:right;">
        <v-btn @click="dayBack" text><i class="fal fa-chevron-left"></i></v-btn>
        <v-btn @click="dayForward" :disabled="todayDate === cardDate" text><i class="fal fa-chevron-right"></i></v-btn>
      </v-flex>
    </v-layout>
    <p>{{ formattedDate }} <i style="margin-left:5px;cursor:pointer;" @click="d_date_select = true;" class="fad fa-calendar-day"></i></p>
    <v-layout row wrap style="margin-left:0;margin-right:0;margin-top:20px;">
      <v-flex md6 sm12 xs12>
        <EntryCard :date="cardDate" style="margin: 5px;"></EntryCard>
      </v-flex>
      <v-flex md6 sm12 xs12>
        <MoodCard :date="cardDate" style="margin: 5px;"></MoodCard>
      </v-flex>
    </v-layout>

    <v-dialog v-model="d_date_select" max-width="400">
      <v-card>
        <v-card-title class="headline">{{ $t("today.select_date") }}</v-card-title>
        <v-card-text style="text-align:center;">
          <v-date-picker :allowed-dates="allowedDates" :first-day-of-week="1" :value="cardDate" @change="selectDate"></v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EntryCard from "../components/EntryCard";
import MoodCard from "../components/MoodCard";

export default {
  data() {
    return {
      current_date: new Date(),

      //Dialogs
      d_date_select: false,

      l_today: false,

      add_title: "",
      add_text: "",

      today: null,
      mood: 0
    };
  },
  mounted() {},
  computed: {
    cardDate() {
      return (
        this.current_date.getFullYear() +
        "-" +
        (this.current_date.getMonth() + 1 + "").padStart(2, "0") +
        "-" +
        (this.current_date.getDate() + "").padStart(2, "0")
      );
    },
    formattedDate() {
      var months = [
        this.$t("general.month.january"),
        this.$t("general.month.february"),
        this.$t("general.month.march"),
        this.$t("general.month.april"),
        this.$t("general.month.may"),
        this.$t("general.month.june"),
        this.$t("general.month.july"),
        this.$t("general.month.august"),
        this.$t("general.month.september"),
        this.$t("general.month.october"),
        this.$t("general.month.november"),
        this.$t("general.month.december")
      ];

      var day_of_week = [
        this.$t("general.day_of_week.sunday"),
        this.$t("general.day_of_week.monday"),
        this.$t("general.day_of_week.tuesday"),
        this.$t("general.day_of_week.wednesday"),
        this.$t("general.day_of_week.thursday"),
        this.$t("general.day_of_week.friday"),
        this.$t("general.day_of_week.saturday")
      ];

      return (
        day_of_week[this.current_date.getDay()] + ", " +
        this.current_date.getDate() +
        ". " +
        months[this.current_date.getMonth()] +
        " " +
        this.current_date.getFullYear()
      );
    },
    todayDate() {
      var today = (new Date());
      return (
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 + "").padStart(2, "0") +
        "-" +
        (today.getDate() + "").padStart(2, "0")
      );
    }
  },
  methods: {
    dayBack() {
      this.current_date.setDate(this.current_date.getDate() - 1);
      this.current_date = new Date(this.current_date);
    },
    dayForward() {
      this.current_date.setDate(this.current_date.getDate() + 1);
      this.current_date = new Date(this.current_date);
    },

    selectDate(date) {
      this.current_date = new Date(date);
      this.d_date_select = false;
    },

    // Formatters
    allowedDates(val) {
      var date = new Date(val);
      return ((new Date()) - date) > 0;
    },
  },
  components: {
    MoodCard,
    EntryCard
  }
};
</script>

<style>
.mx-auto {
  margin-bottom: 30px;
}
</style>