<template>
  <v-card>
    <v-card-title>{{ $t("mood_card.mood") }}</v-card-title>
    <v-card-text v-if="loaded">
      <v-layout row style="text-align:center;margin:20px 0 20px 0;">
        <v-flex xs4>
          <i
            class="fad fa-smile mood_icon"
            @click="setMood(1)"
            :class="{active: value == 1, dark: $vuetify.theme.dark}"
          ></i>
        </v-flex>
        <v-flex xs4>
          <i
            class="fad fa-meh mood_icon"
            @click="setMood(2)"
            :class="{active: value == 2, dark: $vuetify.theme.dark}"
          ></i>
        </v-flex>
        <v-flex xs4>
          <i
            class="fad fa-frown mood_icon"
            @click="setMood(3)"
            :class="{active: value == 3, dark: $vuetify.theme.dark}"
          ></i>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-text style="text-align:center;font-size:40px;margin:20px;" v-else>
      <v-progress-circular :indeterminate="true"></v-progress-circular>
    </v-card-text>
  </v-card>
</template>

<script>
import Mood from "../logic/Mood";
import { Notifications } from "../notification";

export default {
  props: {
    date: String
  },
  data() {
    return {
      loaded: false,

      value: 0
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      var mood_response = await Mood.loadSpecifcDate(this.date);
      if (mood_response.isOK && mood_response.data) {
        this.value = mood_response.data[0].data.value;
      } else {
        this.value = 0;
      }
      this.loaded = true;
    },
    async setMood(value) {
      var response = await Mood.update(value, this.date);
      if (response.isOK) {
        this.value = value;
        Notifications.show(this.$t("mood_card.notification.mood_saved"));
      }
    }
  },
  watch: {
    date() {
      this.loaded = false;
      this.init();
    }
  }
};
</script>

<style>
.mood_icon {
  font-size: 40px;
  transition: 0.3s;
  color: rgb(85, 85, 85);
  cursor: pointer;
}
.mood_icon.dark {
  color: rgb(177, 177, 177);
}
.mood_icon.active {
  color: black;
  transform: scale(1.2);
}
.mood_icon:not(.active) {
  opacity: 0.3;
}
.mood_icon:hover {
  transform: scale(1.2);
  transition: 0.3s;
  color: black;
}
.mood_icon.dark:hover,
.mood_icon.active.dark {
  color: white;
}
</style>