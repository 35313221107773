import { storage } from "../schmucklicloud";

const Entries = {
    add: async function(title, text, date) {
        var response = await storage.insert("entries", {
            title: title,
            text: text,
            date: date
        });

        return response;
    },

    edit: async function(id, title, text) {
        var response = await storage.update("entries", id, {
            title: title,
            text: text
        });

        return response;
    },

    loadToday: async function() {
        var today = new Date();
        var s_today =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1 + "").padStart(2, "0") +
            "-" +
            (today.getDate() + "").padStart(2, "0");
        
        return this.loadSpecifcDate(s_today);
    },
    loadSpecifcDate: async function(date) {
        var response = await storage.get("entries", [
            {
                column: "date",
                operator: "==",
                value: date
            }
        ]);

        return response;
    }
};

export default Entries;
